:root {
  --primary-color: #e64607f2;
  --secondary-color: #6c757d;
  --background-color: #f5f5f5;
  --text-color: #333;
  --font-family: 'Arial', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;

  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.stage {
  position: relative;
  background: #FFF;
  width: 50.6vh;
  height: 90vh;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

}

.stage::-webkit-scrollbar {
  display: none;
}


.content {
  max-height: 100vh;
  overflow: hidden;
}


.container {
  display: flex;
  flex-direction: row;
  /* or inline-flex */
}

.container {
  display: flex;
  /* or inline-flex */
}

.b_nav {
  z-index: 100;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 10;
  background: var(--primary-color);
  text-align: center;
  margin: 0;
  padding: 0;
}

.sidepanel {
  position: absolute;
  width:50px;
  bottom: 5%;
  font-size: 40px;
  padding:6px;
  margin:12px;
  z-index: 11;
}

.sidepanel svg,
.sidepanel a {
  color: #FFF;

}


.videopane * {
  position: absolute;
  right:0;
}

a:hover {
  outline: none;
}

.b_nav li {

  display: inline-block;
  padding: 15px;
  width: 6vh;
  font-size: 30px;
  color: var(--background-color);
}

.b_nav li svg {

  color: var(--background-color);
}

a {
  text-decoration: none;
  color: var(--text-color);
}

.b_nav a:hover svg{
  text-decoration: underline;
  color: var(--secondary-color);
}

@media only screen and (max-width: 600px) {}
