

.videopane {
  position: relative;
  background: #000;
  top: 0;
  width: 100%;
  height: 90vh;
  margin: 0;
  scroll-snap-align: start;
}


video {
  width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

iframe {
  height: 100%;
  width: 100%;
  position: relative;
}
.videopane * {
  position: absolute;
}

video {
  width: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

